import { DeviceType } from '@/context/device-context'

import { sendEvent } from './trackingUtils'

type GTMBaseEvent = {
  device?: 'desktop' | 'mobile'
  event: string
  event_value?: string
}

type GTMInteractionEvent = GTMBaseEvent & {
  interaction_source?: string
  interaction_target: string
  interaction_type: string
}

type GTMWebVitalsEvent = GTMBaseEvent & {
  metric_name: string
  metric_value: number
}

type GTMMilestoneEvent = GTMBaseEvent & {
  milestone_label: string
}

type GTMSysCommEvent = GTMBaseEvent & {
  system_comms_label: string
}

/** The prefix needed to trigger tracking events on Analytics */
//(see the trigger "Forward custom events to GA4" here https://tagmanager.google.com/#/container/accounts/1531476738/containers/60002954/workspaces/32/triggers)
const EVENT_PREFIX = 'cmevent-'

export const sendInteractionEvent = (eventData: GTMInteractionEvent) => {
  const fsEventName = `${eventData.interaction_type}-${eventData.interaction_target}`
  sendEvent({
    ...eventData,
    event: `${EVENT_PREFIX}${eventData.event}`,
    event_type: 'interaction',
    fs_event: fsEventName,
    interaction_source: eventData.interaction_source || 'self',
  })
}

export const sendWebVitalsEvent = (eventData: GTMWebVitalsEvent) => {
  sendEvent({ ...eventData, event: `${EVENT_PREFIX}${eventData.event}`, event_type: 'web_vitals_metric' })
}

export const sendMilestoneEvent = (eventData: GTMMilestoneEvent) => {
  const fsEventName = eventData.milestone_label
  sendEvent({
    ...eventData,
    event: `${EVENT_PREFIX}${eventData.event}`,
    event_type: 'milestone',
    fs_event: fsEventName,
  })
}

export const sendSysCommEvent = (eventData: GTMSysCommEvent) => {
  const fsEventName = `system-comms-${eventData.system_comms_label}`
  sendEvent({
    ...eventData,
    event: `${EVENT_PREFIX}${eventData.event}`,
    event_type: 'system_comms',
    fs_event: fsEventName,
  })
}

export type FieldUpdateEventHandler = (fieldName: string, device?: DeviceType, interactionSource?: string) => void

export const sendFieldUpdateEvent: FieldUpdateEventHandler = (
  fieldName: string,
  device?: DeviceType,
  interactionSource?: string
) => {
  sendInteractionEvent({
    device: device,
    event: `FieldUpdate-${fieldName}`,
    interaction_source: interactionSource,
    interaction_target: fieldName,
    interaction_type: 'FieldUpdate',
  })
}

export const sendFormBackEvent = (device?: DeviceType, interaction_source?: string): void => {
  sendInteractionEvent({
    device: device,
    event: 'ClickPrevStepButton',
    interaction_source: interaction_source || 'self',
    interaction_target: 'PrevStepButton',
    interaction_type: 'Click',
  })
}

export const sendFormNextEvent = (device?: DeviceType, interaction_source?: string): void => {
  sendInteractionEvent({
    device: device,
    event: 'ClickNextStepButton',
    interaction_source: interaction_source || 'self',
    interaction_target: 'NextStepButton',
    interaction_type: 'Click',
  })
}
