import React, { createContext, useContext, useState, useEffect } from 'react'

import { mediaQueryStrings } from '@/style-theme/media-queries'

export type DeviceType = 'desktop' | 'mobile'

export type Viewport = {
  device?: DeviceType
  height?: number
  width?: number
}

const defaultContextValue: Viewport = {
  device: 'mobile',
  height: 0,
  width: 0,
}

export const DeviceContext = createContext<Viewport>(defaultContextValue)

export const useDevice = () => {
  const context = useContext(DeviceContext)

  if (!context) {
    throw new Error(`useDevice must be used within a DeviceProvider`)
  }

  return context.device
}

export const useViewport = () => {
  const context = useContext(DeviceContext)

  if (!context) {
    throw new Error(`useDevice must be used within a DeviceProvider`)
  }

  return { height: context.height, width: context.width }
}

export const DeviceProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<Viewport>(defaultContextValue)

  useEffect(() => {
    const matchMedia = () => (window.matchMedia(mediaQueryStrings.large).matches ? 'desktop' : 'mobile')

    const handleWindowResize = () => {
      const device = matchMedia()

      const height = window.innerHeight
      // Fix instagram window.innerwidth issue
      const width = window.screen.width < window.innerWidth ? window.screen.width : window.innerWidth

      setState({
        device,
        height,
        width,
      })
    }

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize, { passive: true })
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return <DeviceContext.Provider value={state}>{children}</DeviceContext.Provider>
}
