export const isValidForTracking = (value: unknown): boolean =>
  (typeof value !== 'string' || value !== '') && value !== undefined && value !== null

export const getPage = (): string => document.location.pathname + document.location.search

export const getLocation = (): string =>
  document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search

export const dataLayerPush = (params: Record<string, unknown>): void => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      location: getLocation(),
      page: getPage(),
      ...params,
    })
  }
}

type GTMEventProps = {
  event: string
  event_type: 'interaction' | 'web_vitals_metric' | 'milestone' | 'system_comms'
}

type GTMAnalitycsEvent = Record<string, string | number> & GTMEventProps

export const sendEvent = (args: GTMAnalitycsEvent): void => {
  if (typeof window.dataLayer !== 'undefined') {
    dataLayerPush(args)
  } else {
    console.warn('window.dataLayer is undefined')
  }
}
