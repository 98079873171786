import '../styles/globals.css'
import '../fonts/fonts.css'
import { ThemeProvider } from '@emotion/react'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { Fragment, useEffect, useRef } from 'react'
import TagManager from 'react-gtm-module'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { DeviceProvider } from '@/context/device-context'
import { sendWebVitalsEvent } from '@/utils/tracking/eventHandlers'
import { InitConsentSolution } from '@consent-solution/InitConsentSolution'
import { mediaQueryStrings } from 'style-theme/media-queries'
import { casavoMutuiTheme } from 'style-theme/theme'

const tagManagerArgs = {
  auth: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID!,
  preview: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW,
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (metric.label === 'web-vital') {
    sendWebVitalsEvent({
      device: window.matchMedia(mediaQueryStrings.large).matches ? 'desktop' : 'mobile',
      event: `WebVitals-${metric.name}`,
      metric_name: metric.name,
      /** convert CLS value from fractional to integer because values must be integers */
      metric_value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
    })
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])

  /* Create a new QueryClient instance inside of your app, and on an instance ref (or in React state). This ensures that data is not shared between different users and requests, while still only creating the QueryClient once per component lifecycle.
   * https://react-query.tanstack.com/guides/ssr
   *
   */
  const queryClient = useRef(new QueryClient())

  return (
    <Fragment>
      {/* Iubenda Consent Solution snippet */}
      <InitConsentSolution />
      <Script async src="https://cdn.iubenda.com/cons/iubenda_cons.js" type="text/javascript" />
      <QueryClientProvider client={queryClient.current}>
        <ThemeProvider theme={casavoMutuiTheme}>
          <DeviceProvider>
            <Head>
              <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
            </Head>
            <Hydrate state={pageProps.dehydratedState}>
              <Component {...pageProps} />
            </Hydrate>
          </DeviceProvider>
        </ThemeProvider>
        {/* only show if not in iframe */}
        {typeof window !== 'undefined' && window.top === window && <ReactQueryDevtools initialIsOpen={false} />}{' '}
      </QueryClientProvider>
    </Fragment>
  )
}

export default MyApp
