type MediaQuerySize = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall'

export const breakpoints: Record<MediaQuerySize, number> = {
  large: 1024,
  medium: 768,
  small: 568,
  xlarge: 1200,
  xsmall: 360,
  xxlarge: 1480,
} as const

export const mediaQueryStrings: Record<MediaQuerySize, string> = {
  large: `(min-width:${breakpoints.large}px)`,
  medium: `(min-width:${breakpoints.medium}px)`,
  small: `(min-width:${breakpoints.small}px)`,
  xlarge: `(min-width:${breakpoints.xlarge}px)`,
  xsmall: `(min-width:${breakpoints.xsmall}px)`,
  xxlarge: `(min-width:${breakpoints.xxlarge}px)`,
} as const

export const mq: Record<MediaQuerySize, string> = {
  large: `@media${mediaQueryStrings.large}`,
  medium: `@media${mediaQueryStrings.medium}`,
  small: `@media${mediaQueryStrings.small}`,
  xlarge: `@media${mediaQueryStrings.xlarge}`,
  xsmall: `@media${mediaQueryStrings.xsmall}`,
  xxlarge: `@media${mediaQueryStrings.xxlarge}`,
} as const
