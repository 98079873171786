// TODO: replace white with greyscale[0]

type MultiShadeColors = 'orange' | 'deepGreen' | 'green' | 'greyscale' | 'success' | 'warning' | 'error'

type SingleShadeColors = 'lightBlue' | 'offWhite'

type Shades = {
  0?: string
  50?: string
  100: string
  200?: string
  300?: string
  400: string
  500: string
  600: string
  700?: string
  800?: string
}

type Radius = {
  l: number
  m: number
  s: number
}

type Spacers = {
  '2xl': 48
  '3xl': 64
  '4xl': 80
  '5xl': 96
  l: 24
  m: 16
  'm/l': 20
  s: 8
  's/m': 12
  xl: 32
  xs: 4
  xxs: 2
}

export type CasavoMutuiThemePalette = Record<MultiShadeColors, Shades> & Record<SingleShadeColors, string>

type Shadows = {
  floating: string
  lifted: string
  raised: string
  soft: string
}

export interface CasavoMutuiTheme {
  colors: CasavoMutuiThemePalette
  radius: Radius
  shadows: Shadows
  spacings: Spacers
}

/** Color Palette */
const casavoMutuiPalette: CasavoMutuiThemePalette = {
  deepGreen: {
    100: '#E6ECEB',
    400: '#9BB1B0',
    500: '#517774',
    600: '#063D39',
    700: '#05312E',
  },
  error: {
    100: '#FDEEEE',
    400: '#F39A9A',
    500: '#EF7979',
    600: '#EB5757',
    700: '#D44E4E',
    800: '#BC4646',
  },
  green: {
    100: '#E9F9F6',
    400: '#A8E6DA',
    500: '#66D4BF',
    600: '#25C1A3',
    700: '#1E9A82',
  },
  greyscale: {
    0: '#FFF',
    50: '#F5F5F5',
    100: '#E8E8E8',
    200: '#D4D4D3',
    300: '#B3B3B2',
    400: '#737372',
    500: '#525251',
    600: '#1D1D1B',
  },
  lightBlue: '#D6F5FF',
  offWhite: '#F6F6F1',
  orange: {
    100: '#FFF5E9',
    400: '#FFD6A5',
    500: '#FFB862',
    600: '#FF991F',
    700: '#E68A1C',
  },
  success: {
    100: '#F0F9EE',
    400: '#A4D99A',
    500: '#85CD79',
    600: '#67C057',
    700: '#5DAD4E',
    800: '#529A46',
  },
  warning: {
    100: '#FFF9EC',
    400: '#FFD98E',
    500: '#FFCD69',
    600: '#FFC043',
    700: '#E6AD3C',
    800: '#CC9A36',
  },
}

/** Radii */
const radius: Radius = {
  l: 16,
  m: 8,
  s: 4,
}

/** Shadows */
const shadows: Shadows = {
  floating: '0px 6px 24px rgba(29, 29, 27, 0.24)',
  lifted: '0px 2px 8px rgba(29, 29, 27, 0.24)',
  raised: '0px 4px 16px rgba(29, 29, 27, 0.24)',
  soft: '0px 1px 4px rgba(29, 29, 27, 0.24)',
}

/** Spacings */
const spacings: Spacers = {
  '2xl': 48,
  '3xl': 64,
  '4xl': 80,
  '5xl': 96,
  l: 24,
  m: 16,
  'm/l': 20,
  s: 8,
  's/m': 12,
  xl: 32,
  xs: 4,
  xxs: 2,
}

export const casavoMutuiTheme: CasavoMutuiTheme = {
  colors: casavoMutuiPalette,
  radius,
  shadows,
  spacings,
}
