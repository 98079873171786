import Script from 'next/script'

export const InitConsentSolution: React.FC = () => {
  const apiKey = process.env.NEXT_PUBLIC_IUBENDA_CS_KEY

  return (
    <Script id="init-iubenda-consent-solution">
      {`var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "${apiKey}", log_level: "debug"}]);`}
    </Script>
  )
}
